.dashboardcode-bsmultiselect ul.form-control {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
  cursor: text;
  list-style-type: none;
}
.dashboardcode-bsmultiselect ul.form-control input {
  height: auto;
  padding: 0;
  margin: 0;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
}
.dashboardcode-bsmultiselect ul.form-control.disabled {
  background-color: #e9ecef;
}
.dashboardcode-bsmultiselect ul.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.dashboardcode-bsmultiselect ul.form-control > li.badge {
  padding-left: 0;
  padding-inline-start: 0;
  padding-inline-end: 0.5rem;
  line-height: 1.5em;
}
.dashboardcode-bsmultiselect ul.form-control > li.badge button.close {
  float: none;
  font-size: 1.5em;
  line-height: 0.9em;
}
.dashboardcode-bsmultiselect ul.form-control > li.badge span.disabled {
  opacity: 0.65;
}
.dashboardcode-bsmultiselect ul.form-control.focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.dashboardcode-bsmultiselect ul.form-control.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
.dashboardcode-bsmultiselect ul.form-control.form-control-sm input {
  font-size: 0.875rem;
}
.dashboardcode-bsmultiselect ul.form-control.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}
.dashboardcode-bsmultiselect ul.form-control.form-control-lg input {
  font-size: 1.25rem;
}
.was-validated .dashboardcode-bsmultiselect ul.form-control:valid, .dashboardcode-bsmultiselect ul.form-control.is-valid {
  border-color: #28a745;
}
.was-validated .dashboardcode-bsmultiselect ul.form-control:valid.focus, .dashboardcode-bsmultiselect ul.form-control.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .dashboardcode-bsmultiselect ul.form-control:invalid, .dashboardcode-bsmultiselect ul.form-control.is-invalid {
  border-color: #dc3545;
}
.was-validated .dashboardcode-bsmultiselect ul.form-control:invalid.focus, .dashboardcode-bsmultiselect ul.form-control.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dashboardcode-bsmultiselect div.dropdown-menu {
  list-style-type: none;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul > li {
  display: block;
  width: 100%;
  padding: 0 0.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul > li .custom-control {
  justify-content: flex-start;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul > li .custom-control .custom-control-input, .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .custom-control .custom-control-label {
  cursor: inherit;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul > li .custom-control .custom-control-input.disabled ~ .custom-control-label {
  color: #6c757d;
}
.dashboardcode-bsmultiselect div.dropdown-menu .hover {
  color: var(--primary);
  background-color: #e9ecef;
}
.dashboardcode-bsmultiselect div.dropdown-menu + div.alert-warning {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  z-index: 4;
  font-size: small;
}
.dashboardcode-bsmultiselect.input-group.input-group-sm ul.form-control {
  min-height: calc(1.5em + 0.5rem + 2px);
}
.dashboardcode-bsmultiselect.input-group.input-group-sm ul.form-control input {
  font-size: 0.875rem;
}
.dashboardcode-bsmultiselect.input-group.input-group-lg ul.form-control {
  min-height: calc(1.5em + 1rem + 2px);
}
.dashboardcode-bsmultiselect.input-group.input-group-lg ul.form-control input {
  font-size: 1.25rem;
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .custom-control-input:valid:checked ~ .custom-control-label {
  color: #212529;
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .custom-control-input:valid:not(:checked) ~ .custom-control-label {
  color: #212529;
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li.hover .custom-control-input:valid:checked ~ .custom-control-label {
  color: var(--primary);
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li.hover .custom-control-input:valid:not(:checked) ~ .custom-control-label {
  color: var(--primary);
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .custom-control-input:valid:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .custom-control-input:valid:not(:checked) ~ .custom-control-label::before {
  border: #adb5bd solid 1px;
}

/*# sourceMappingURL=BsMultiSelect.bs4.css.map */
